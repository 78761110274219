@import "app/assets/css/variables.scss";

.order-message {
  display: flex;
  align-items: center;
  gap: $spacing-03;
  width: 100%;
  color: #821218;
  background-color: rgba(218, 30, 40, 0.10);
  border-radius: $spacing-03;
  line-height: 24px;
  font-size: 16px;
  padding: $spacing-03 $spacing-05;
  margin-top: $spacing-04;

  svg {
    font-size: 18px;
  }
}