.product-selector-place-order {
  .empty-header .accordion-button::after {
    display: none;
  }

  .product-display {
    width: 100%;
  }

  .product-selector-body {
    display: flex;
    gap: 16px;

    .no-matching-products {
      white-space: nowrap;
    }

    .accordion {
      width: 100%;
      max-width: 260px;

      .accordion-item {
        .order-row {
          cursor: pointer;

          &:hover {
            background-color: #e7f1ff;
          }

          &:last-child td {
            border-style:none;
          }
        }
      }
    }

    .product-container {
      display: flex;
      flex-wrap: wrap;
      gap: 16px;
    }

    .category-container {
      width: 100%;
      padding-top: 25px;

      min-width: 100px;
      flex: 1 1 100px;

      .category-title {
        font-size: 20px;
        padding-bottom: 5px;
      }

      &:first-child {
        padding-top: 0px;
      }
    }
  }
}