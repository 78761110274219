@import "app/assets/css/variables.scss";

.sidebar {
  position: fixed;
  top: 68px;
  left: 0;
  width: 229px;
  height: calc(100% - 32px);
  padding: $spacing-05;
  background-color: #121517;
  transform: translateX(-100%);
  transition: transform 0.3s ease-in-out;
  overflow-y: auto; 

  .sidebar-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    padding: $spacing-03;
    z-index: 1;

    .sidebar-link {
      display: flex;
      width: 100%;
      line-height: 26px;
      margin-bottom: $spacing-04;
      padding: $spacing-02 $spacing-04;
      align-items: center;
      gap: $spacing-04;
      border-radius: 8px;
      font-size: 16px;
      color: #B5B5B5;
      text-decoration: none;

      svg {
        width: 16px;
        height: 16px;
      }

      &:hover {
        color: #B5B5B5;
        background-color: #2E2E2E;
      }

      &.active {
        color: #171717;
        background: #8BE8FC;
      }
    }

    .admin-header {
      padding: $spacing-05 0 $spacing-03 0;
      font-size: 12px;
      color: #878787;
    }

    .logout-button {
      margin-top: auto; 
      margin-bottom: $spacing-03;
    }
  }

  &.show {
    transform: translateX(0);
  }

  /* Scrollbar styles */
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #b3b3b3; 
    border-radius: 4px;
  }
  &::-webkit-scrollbar {
    width: 8px; 
    height: 8px; 
  }
}