@import "app/assets/css/variables.scss";

.transformation-details {
  padding: $spacing-07;

  .transformation-header {
    display: flex;
    justify-content: space-between;
    font-size: 32px;
    font-weight: 600;
    padding-bottom: $spacing-05;

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: flex-start;
    }

    .action-buttons {
      display: flex;
      justify-content: flex-end;

      @media (max-width: 768px) { 
        justify-content: flex-start;
        width: 100%;
        margin-top: $spacing-03;
      }

      .gooten-button {
        margin-left: $spacing-05;

        @media (max-width: 768px) {
          margin-left: 0;
          margin-right: $spacing-03;
        }
      }
    }
  }

  .transformation-data {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    column-gap: $spacing-05;

    @media screen and (max-width: 1200px) {
      grid-template-columns: 1fr 1fr; 
    }

    @media screen and (max-width: 900px) {
      grid-template-columns: 1fr; 
    }
  }

  .code-snippet {
    margin-bottom: 0px;

    border: 1px solid #E8E8E8;
    border-radius: 8px;

    .overflow-guard,
    .monaco-editor {
      border-radius: 8px;
    }

  }

  .test-snippet-card,
  .transformation-data-card {
    margin-bottom: 30px;

    .test-snippet-body {
      display: flex;
      justify-content: space-between;
      align-items: stretch;

      @media (max-width: 991px) {
        flex-direction: column;
        align-items: flex-start;

        .transform-button {
          margin: 15px auto;
        }

        div {
          width: 100%;
        }
      }

      .test-against-deployed {
        flex: 1;
        margin-bottom: 0px;

        .content-type-method-container {
          display: flex;
          justify-content: space-between;
          gap: 10px;

          .header-key,
          .header-value {
            margin-bottom: 16px;
          }
        }
      }

      .code-snippet-input,
      .code-snippet-output {
        flex: 1;
        margin-bottom: 0px;

        textarea {
          width: 100%;
          min-height: 200px;
        }
      }

      .code-snippet-input2 {
        height: 150px;
        margin-bottom: 0px;

        textarea {
          height: 100%;
          width: 100%;
        }
      }

      .destination-url,
      .content-method,
      .content-type {
        margin-bottom: 16px;
        flex: 1;
      }

      .transform-button {
        display: flex;
        justify-content: center; 
        align-items: center; 
        width: 180px;
        text-align: center;

        button {
          svg {
            margin: 0 0 2px 5px;
          }
        }
      }

      @keyframes highlight {
        0% {
          background-color: #fff;
          border-color: #ced4da;

        }

        50% {
          background-color: rgb(255, 255, 212);
          border-color: #6f7172;
        }

        100% {
          background-color: #fff;
          border-color: #ced4da;
        }
      }

      .highlight-animation>textarea {
        animation-name: highlight;
        animation-duration: 2.0s;
      }
    }

    @media (max-width: 991px) {
      .optional-message {
        display: none;
      }
    }
  }
}