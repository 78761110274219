@import "app/assets/css/variables.scss";

.facility-details-view {
  .facility-facility-info {
    display: flex;
    justify-content: space-between;
    gap: $spacing-05;

    .left-col {
      flex: 2; 
    }
  
    .right-col {
      flex: 1; 
    }

    @media (max-width: 1200px) {
      flex-direction: column;
    }
  }

  .facility-details-card {
    &.edit-mode {
      padding-bottom: 0px;
    }

    .facility-information {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      column-gap: $spacing-05;
  
      &.edit-mode {
        grid-template-columns: 1fr 1fr;

        @media screen and (max-width: 900px) {
          grid-template-columns: 1fr; 
        }
      }

      &.view-mode {
        margin-bottom: $spacing-05;
        @media screen and (max-width: 1100px) {
          grid-template-columns: 1fr 1fr;
        }

        @media screen and (max-width: 750px) {
          grid-template-columns: 1fr; 
        }
      }
  
      .data-point {
        &:not(:last-child) {
          margin-bottom: $spacing-04;
        }
      }
    }

    .facility-contact-information {
      font-size: 20px;
      font-weight: 700;
      line-height: 32px;
      color: #2E2E2E;
      padding: 0 0 $spacing-04 0;
      border-bottom: 1px solid #E8E8E8;
      margin: -5px 0 $spacing-04 0;
    }
  
    .action-buttons {
      display: flex;
      justify-content: flex-end;
      gap: 12px;
      
      .btn-primary {
        margin-left: 8px;
      }

      &.new-facility {
        position: absolute;
        top: 110px;
        right: 32px;
      }
    }
  }
}