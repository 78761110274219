@import "app/assets/css/variables.scss";

.place-an-order {
  margin: $spacing-07;

  .vendor-name-container {
    display        : flex;
    justify-content: space-between;
    font-size      : 24px;
    margin-bottom  : 15px;
  }

  .action-buttons {
    display: flex;
    flex-direction: column;
    align-items: inherit;
    margin-top: auto;

    button {
      max-width: 220px;
      height: 38px;
    }
  }
}