@import "app/assets/css/variables.scss";

.gooten-card {
  border-radius: $spacing-05;
  padding: $spacing-05;
  
  background-color: #FCFCFC;
  color: #212529;
  box-shadow: 0px 2px 3px 0px rgba(23, 23, 23, 0.10);

  .card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: 26px;
    font-size: 20px;
    font-weight: 700;
    
    &:not(.collapsed) {
      padding-bottom: $spacing-04;
      border-bottom: 1px solid #E8E8E8;
    }

    &.hide-divider {
      border-bottom: none;
      padding-bottom: 0;
    }
  }

  .card-body {
    padding: $spacing-04 0 0 0;
  }
}