@import "app/assets/css/variables.scss";

.facility-products-view {
  .vendor-products-card {
    .search-bar-and-filters {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: $spacing-04 0;
  
      .search-bar {
        width: 100%;
        // max-width: 500px;
      }
    }

    .product-status-chip {
      .status-chip {
        margin: 0 auto;
      }
    }  

    .product-options {
      display: flex;
      flex-direction: row;
      gap: $spacing-02;
    }

    .no-product-data {
      text-align: center;
      padding: 50px !important;

      svg {
        margin: -5px 3px 0 0;
      }
    }
  }
}