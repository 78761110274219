@import "app/assets/css/variables.scss";

.topbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 78px;
  padding: $spacing-03 $spacing-05 $spacing-03 $spacing-07;
  background-color: #121517;
  position: fixed;
  top: 0;
  z-index: 3;

  .logo-container {
    display: flex;
    align-items: center;
    width: 240px;
    
    .hamburber-icon-expand {
      display: inline-block;
      width: 18px;
      height: 11px;
      cursor: pointer;
    }

    .hamburber-icon-collapse {
      display: inline-block;
      width: 18px;
      height: 11px;
      cursor: pointer;
    }

    .order-mesh-logo {
      display: flex;
      margin-left: $spacing-04;
      color: #2E2E2E;
      font-size: 24px;
      font-weight: 600;
    }
  }

  .profile-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    color: #fff;

    .profile-name {
      font-size: 16px;
      font-weight: 400;
      line-height: 26px;
      white-space: nowrap;
    }

    .profile-avatar {
      width: 46px;
      height: 46px;
      border: 2px solid #C4C4C4;
      border-radius: 100px;
    }
  }
}