@import "app/assets/css/variables.scss";

.strategy-edit-create {
  margin: $spacing-05;

  .no-bot-padding {
    padding-bottom: 0;
  }

  .strategy-header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 24px;

    .strategy-name {
      display: flex;
      align-items: center;
      row-gap: 0px;
      column-gap: $spacing-04;
      font-size: 32px;
      font-weight: 600;
    }

    .action-buttons {
      display: flex;
      gap: $spacing-05;
    }
  }

  .strategy-name-input {
    max-width: 500px;
    padding-top: $spacing-04;
  }

  .error-message {
    text-align: center;
    padding: 50px 10px;

    svg {
      margin: -7px 3px 0 0;
    }
  }
}