@import "app/assets/css/variables.scss";

.orders-view {
  margin: $spacing-05;

  .orders-header {
    font-size: 32px;
    font-weight: 600;
    padding-bottom: $spacing-04;
  }

  .search-bar-and-filters {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: $spacing-04;

    .search-bar {
      width: 100%;
      // max-width: 500px;
    }
  }

  .gooten-table-header {
    .order-status-header {
      text-align: center;
    }
  }     

  .table-body {
    .order-row {
      .order-status-chip {
        .status-chip {
          margin: 0 auto;
        }
      }
      
      .order-options {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 48px;
      }
    }

    .no-results {
      text-align: center;
      height: 100px;
    }
  }

  .order-load-failed {
    width: 100%;
    text-align: center;
    padding: 50px 0;

    svg {  
      font-size: 18px;
      margin-right: 6px;
    }
  }
}