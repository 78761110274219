@import "app/assets/css/variables.scss";

.actions-and-trigger {
  border: 1px solid #E8E8E8;
  border-radius: 12px;
  padding: $spacing-03 $spacing-04;
  margin-top: $spacing-04;

  .actions-and-trigger-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    font-size: 20px;
    font-weight: 700;
    line-height: 32px;

    .action-buttons {
      display: flex;
      align-items: center;
      gap: $spacing-04;
    }

    &.menu-open {
      border-bottom: 1px solid #E8E8E8;
      padding-bottom: $spacing-03;
      margin-bottom: $spacing-04;
    }
  }

  .actions-and-trigger-body {
    .edit-mode-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .action-buttons {
        display: flex;
        align-items: flex-start;
        gap: $spacing-04;
        margin-top: $spacing-04;
      }
    }

    .selected-event {
      max-width: 400px;
    }

    .api-config-header {
      font-size: 18px;
      font-weight: 700;
      line-height: 30px;
    }

    .api-request-details-section {
      margin-top: $spacing-05;

      .callback-info {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: $spacing-05;

        .gooten-dropdown {
          width: 100%;
          max-width: 125px;
        }

        .gooten-input {
          width: 100%;
        }
      }

      .header-info-section {
        .header-row {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          gap: $spacing-05;

          .custom-header,
          .custom-value {
            width: 100%;
            max-width: 350px;
          }

          .trash-can {
            width: 80px;

            .gooten-button-icon {
              padding-bottom: 18px;
            }
          }
        }
      }
    }

    .header-info-section {
      margin-bottom: $spacing-05;

      .header-row {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        gap: $spacing-05;
      }

      .header-titles {
        font-weight: bold;
        border-bottom: 1px solid #E8E8E8;
        padding-bottom: $spacing-03;
        margin-bottom: $spacing-03;
      }
    }

    .request-response-selectors {
      display: flex;
      flex-direction: row;
      gap: $spacing-05;
      padding-top: $spacing-02;

      font-size: 14px;
      font-weight: 400;
      line-height: 24px;

      div {
        cursor: pointer;
        padding: $spacing-03 $spacing-05;
      }

      div.selected {
        color: 2E2E2E;
        border-bottom: 2px solid #06BCE5;
      }
    }

    .code-snippet-header {
      font-size: 16px;
      font-weight: 700;
      line-height: 26px;
      padding: $spacing-04 0;
    }

    .code-snippet {
      margin-bottom: 0px;

      border: 1px solid #E8E8E8;
      border-radius: 8px;

      .overflow-guard,
      .monaco-editor {
        border-radius: 8px;
      }
    }

    .input-output-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: $spacing-05;
      align-items: center;

      .input-output-header {
        flex: 1;
        margin-bottom: 0px;
      }

      .transform-button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 180px;
        text-align: center;

        button {
          svg {
            margin: 0 0 2px 5px;
          }
        }
      }
    }

    @keyframes highlight {
      0% {
        background-color: #fff;
        border-color: #ced4da;
      }

      50% {
        background-color: rgb(255, 255, 212);
        border-color: #6f7172;
      }

      100% {
        background-color: #fff;
        border-color: #ced4da;
      }
    }

    .highlight-animation>textarea {
      animation-name: highlight;
      animation-duration: 2.0s;
    }
  }

  .view-mode-container {
    .api-request-details-section {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      column-gap: $spacing-07;
      padding-bottom: $spacing-05;

      .callback-url {
        flex: 1;
      }
    }

    .header-info-section {
      display: grid;
      grid-template-columns: auto 1fr;
      column-gap: $spacing-07;

      // target the first instance of the custom-header class
      .column-headers {
        font-size: 16px;
        line-height: 26px;
        color: #878787;
      }
    }
  }

  .gooten-error-message {
    margin: $spacing-04 0;
  }
}
