@import "app/assets/css/variables.scss";

.webhooks-list {
  .search-bar {
    margin-bottom: $spacing-04;
  }

  .table-header {
    .user-id-column {
      width: 225px;
    }
  }

  .table-body {
    .webhook-row {
      .gooten-toggle-switch {
        margin: 0 auto;
      }

      .webhook-status {
        .status-chip,
        button {
          margin: 0 auto;
        }

        button {
          height: 32px;
        }
      }
      .webhook-actions-menu {
        text-align: center;
      }
    }
  }

  .no-matching-results {
    width: 100%;
    text-align: center;
    padding: 50px 0;

    svg {  
      font-size: 18px;
      margin: -6px 3px 0 0;
    }
  }
}