.place-order-product-card {
  width: 150px;
  max-height: fit-content;
  border-radius: 8px; 
  border: 1px solid #ccc;
  transition: filter 0.20s ease-in-out;
  cursor: pointer;

  &:hover {
    background-color: #edf7ff;
    outline: 2px solid #3c7391;

    svg {
      display: block;

    }
  }

  &.selected {
    background-color: #a6e6ff;
    border: 2px solid #3c7391;
    margin: -1px;
  }

  svg {
    position: absolute;
    font-size: 24px;
    font-weight: bold;
    top: 10px;
    right: 10px;
    
    display: none;

    &.red-fill {
      color: rgb(255, 27, 27);
    }

    &.green-fill {
      color: #1bc735;
    }
  }

  .item-image {
    position: relative;
    margin: 10px;

    display: flex;
    justify-content: center;
    align-items: center;
    height: 125px;
    overflow: hidden;

    img {
      border: 1px solid #ccc;
      border-radius: 8px;
      
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }

    .status-chip {
      position: absolute;
      top: 8px;
      left: 8px;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    }
  }

  .item-data {
    margin: 10px;

    .item-quantity,
    .item-id {
      display: flex;
      justify-content: space-between;
    }
  }
}