.selected-product-card {
  display: flex;
  width: 100%;
  position: relative;
  border-radius: 8px; 
  border: 1px solid #ccc;
  transition: filter 0.20s ease-in-out;

  &:hover {
    background-color: #edf7ff;
    outline: 2px solid #3c7391;

    svg {
      display: block;

    }
  }

  &.selected {
    background-color: #a6e6ff;
    border: 2px solid #3c7391;
    margin: -1px;
  }

  svg {
    position: absolute;
    font-size: 24px;
    font-weight: bold;
    top: -6px;
    right: -6px;
    
    display: none;

    &.red-fill {
      color: rgb(255, 27, 27);
    }

    &.green-fill {
      color: #1bc735;
    }
  }

  .item-image {
    margin: 10px;

    display: flex;
    flex-direction: column;
    justify-content: left;
    width: 128px;
    min-width: 128px;
    height: 159px;
    overflow: hidden;

    img {
      border: 1px solid #ccc;
      border-radius: 8px;
      
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }

    .item-title {
      text-align: left;
      margin-top: 10px;
    }
  }

  .item-info {
    margin: 11px 11px 11px 0px;
    width: 100%;
    
    &.loading {
      flex-grow: 1;
      display: flex;
      justify-content: center;

      margin: 11px 11px 11px 0px;
  
      justify-content: center;
    }

    .loading-animation {
      margin-top: 50px;
    }
  }
}