@import "app/assets/css/variables.scss";

.order-item {
  display: flex;
  flex-direction: column;
  gap: $spacing-05;
  
  .item-header {
    display: flex;
    justify-content: space-between;
    gap: $spacing-05;

    .item-details {
      flex: 1 1 auto;
      display: flex;
      justify-content: space-between;
      gap: $spacing-03;

      @media (max-width: 850px) {
        flex-direction: column;
      }

      .details-container {
        display: flex;
        flex-direction: column;
        gap: $spacing-02;

        .id-status {
          display: flex;
          align-items: center;
          gap: $spacing-04;

          .item-name {
            font-size: 16px;
            font-weight: 700;
            line-height: 26px;
          }
        }

        .sku-info {
          display: flex;
          align-items: flex-start;
          gap: $spacing-03;
          flex-wrap: wrap;
        }

        @media (max-width: 1100px) {
          .sku-info {
            flex-direction: column;
          }
        }
      }
    }

    .pricing-info {
      display: flex;
      align-items: flex-start;
      gap: $spacing-05;

      .pricing-item {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        gap: $spacing-02;

        .pricing-title {
          font-size: 16px;
          font-weight: 400;
          line-height: 26px; 
          white-space: nowrap;
          color: #878787;
        }

        .pricing-value {
          font-size: 16px;
          font-weight: 400;
          line-height: 26px;
        }
      }
    }

    .shipment-options {
      display: flex;
      align-items: flex-start;
      margin-top: -5px;
    }
  }
}

