@import "app/assets/css/variables.scss";

.order-search-bar {
  width: 100%;
  margin: 0 $spacing-07 0 $spacing-08;

  .input-group {
    border: 1px solid #595959;
    border-radius: $spacing-04;
    display: flex;

    .input-group-text {
      background-color: #2E2E2E;
      border: none; 
      border-radius: $spacing-04;
      margin-right: -4px;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      color: #878787;
      text-align: center;
    }

    .form-control {
      flex-grow: 1;
      color: #878787;
      border: none;
      padding: $spacing-03 $spacing-02;
      box-shadow: none !important;
      border-radius: $spacing-04;
      background-color: #2E2E2E;
      font-size: 16px;

      &:focus {
        outline: none;
        border-color: transparent;
      }

      &:focus-visible {
        outline: none;
      }

      &::placeholder {  
        color: #878787;  
        opacity: 1; 
      }
    }

    &:focus-within {
      border-color:#E2E2E2; 
      box-shadow: 0 0 0 0.25rem #CBDEFB !important;
    }

    &:hover {
      box-shadow: 0 0 0 1px #E2E2E2;
    }
  }

  .dropdown-menu.show {
    background-color: #FCFCFC;
    padding: $spacing-03;
    margin-top: 5px;
    width: 100%;
    max-width: 400px;
    border-radius: $spacing-04;

    // on smaller screens, the dropdown menu should be full width
    @media (max-width: 900px) {
      max-width: 250px;
    }
  }

  .dropdown-item {
    font-size: 16px;
    line-height: 24px;
    padding: $spacing-03;
    border-radius: $spacing-03;
    color: #2E2E2E;

    &.destructive {
      color: #821218;
      &:hover {
        background-color: rgba(218, 30, 40, 0.10);
      }
    }

    &:hover {
      background-color: #EDEDED;
    }
    &:active {
      background-color: #E0E0E0;
    }
    &:disabled {
      color: #B5B5B5;
    }
  }
}