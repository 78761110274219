@import "app/assets/css/variables.scss";

.integration-details {
  margin: $spacing-05;

  @keyframes rollup {
    0% {
      transform: translateY(100%);
    }
    100% {
      transform: translateY(0%);
    }
  }
  
  .version-number {
    display: inline-block;
    overflow: hidden;
    position: relative;
    height: 1em;
    line-height: 1em;
  }
  
  .version-number span {
    display: block;
    position: absolute;
    animation: rollup 0.5s ease-out;
  }

  .header-card {
    margin-bottom: $spacing-05;

    .header-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      
      .merchant-name {
        display: flex;
        flex-direction: row;
        gap: $spacing-04;
        font-size: 32px;
        font-weight: 600;
      }

      .action-buttons {
        display: flex;
        flex-direction: row;
        gap: $spacing-05;
      }
    }

    .integration-data-points {
      display: flex;
      flex-direction: row;
      gap: $spacing-06;
      margin-top: $spacing-02;
    }
  }

  #tabs {
    z-index: 1000;
  }

  .tabs {
    font-size: 0.8125rem;
  }

  $blue     : rgb(60, 180, 250);
  $blue-lt  : lighten($blue, 20%);
  $text-dark: #636d84;

  .sticky-top {
    position: sticky;
    top     : 0px;
    z-index : 999;
  }

  .responsive {
    width     : 100%;
    overflow-x: auto;
  }

  .tabs {
    display        : table;
    border-collapse: separate;
    table-layout   : auto;

    &.tabs-center {
      margin: auto;
    }

    &.tabs-justify {
      width       : 100%;
      table-layout: fixed;
    }

    a.tab {
      position   : relative;
      display    : table-cell;
      transition : all ease .3s;
      padding    : 1em 1.6em;
      transform  : translate3d(0, 0, 0);
      color      : $text-dark;
      white-space: nowrap;
      cursor     : pointer;

      &:hover {
        color: $blue;
      }

      &:after {
        transition   : all .3s cubic-bezier(1, 0, 0, 1);
        will-change  : transform, box-shadow, opacity;
        position     : absolute;
        content      : '';
        height       : 3px;
        bottom       : 0px;
        left         : 0px;
        right        : 0px;
        border-radius: 3px 3px 0px 0px;
        background   : $blue-lt;
        box-shadow   : 0px 4px 10px 3px rgba($blue, .15);
        opacity      : 0;
        transform    : scale(0, 1);
      }

      &.active {
        color: $blue;

        &:after {
          opacity  : 1;
          transform: scale(1, 1);
        }
      }
    }
  }
}