.customer-details-card {
  .data-value {
    font-size: 16px;
    line-height: 26px;
    word-wrap: break-word;
  }

  .pencil-icon {
    cursor: pointer;
  }

  .action-buttons {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    
    .btn-primary {
      margin-left: 8px;
    }
  }
}