@import "app/assets/css/variables.scss";

.basic-details {
  margin: $spacing-05;

  .integrations-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    font-size: 24px;
    margin-bottom: $spacing-05;

    .integrations-title {
      display: flex;
      align-items: center;
      row-gap: 0px;
      column-gap: $spacing-04;
      font-size: 32px;
      font-weight: 600;
    }

    .action-buttons {
      display: flex;
      column-gap: $spacing-04;
    }
  }

  .field-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: $spacing-05;
    margin-top: $spacing-05;

    @media screen and (max-width: 900px) {
      grid-template-columns: 1fr; 
    }
  }

  .gooten-error-message {
    margin: $spacing-05 0 $spacing-02 0;
  }

  .integrations-error{
    margin-bottom: $spacing-05; 
  }
}