@import "app/assets/css/variables.scss";

.place-an-order-details {
  max-width: 1000px;
  margin: $spacing-07;

  .data-dropdown {
    max-width: 473px;
  }

  .action-buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: auto;
    gap: 10px;

    button {
      max-width: 220px;
      height: 38px;
    }
  }
}