@import "app/assets/css/variables.scss";

.search-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;

  .filter-button {
    width: 126px;
    min-width: 126px;

    svg {
      margin-right: 4px;
      margin-top: -3px;
    }
  }

  .cta-button {
    width: 180px;
    min-width: 180px;

    svg {
      margin-right: 5px;
      margin-top: -1px;
      font-size: 16px;
    }
  }

  .input-group {
    border: 1px solid #E2E2E2;
    border-radius: $spacing-04;
    display: flex;

    .input-group-text {
      background-color: #FCFCFC;
      border: none; 
      border-radius: $spacing-04;
      font-size: 16px;
      font-weight: 400;
      line-height: 26px;
      color: #495057;
      text-align: center;

      &#clear-search {
        cursor: pointer;
        &:hover {
          color: #E6474F;
        }
      }
    }

    .form-control {
      flex-grow: 1;
      color: #2E2E2E;
      border: none;
      padding: $spacing-04 $spacing-04 $spacing-04 0;
      box-shadow: none !important;
      border-radius: $spacing-04;
      background-color: #FCFCFC;

      &:focus {
        outline: none;
        border-color: transparent;
      }

      &:focus-visible {
        outline: none;
      }

      &::placeholder {  
        color: #878787;  
        opacity: 1; 
      }
    }

    &:focus-within {
      border-color:#E2E2E2; 
      box-shadow: 0 0 0 0.25rem #CBDEFB !important;
    }

    &:hover {
      box-shadow: 0 0 0 1px #E2E2E2;
    }
  }
}