.groups-menu {
  padding: 0 2px;
  border: 1px solid #595959;
  border-radius: 12px;
  background-color: #2E2E2E;
  margin: 0 -20px 0 40px;
  display: flex;

  button {
    background-color: #2E2E2E !important;
  }
}