@import "app/assets/css/variables.scss";

.filter-component {
  background-color: #FCFCFC;
  margin-bottom: $spacing-05;

  .no-bot-padding {
    padding-bottom: 0 !important;
  }
  
  .filter-component-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .filter-header {
      font-size: 18px;
      font-weight: 700;
      line-height: 30px;
      color: #2E2E2E;
    }

    .optional-filter-message {
      font-size: 16px;
      font-weight: 400;
      line-height: 26px;
    }

    .filter-name-input {
      font-size: 16px;
      font-weight: 400;
      font-weight: 400;
      line-height: 26px;
      width: 100%;
      max-width: 400px;
    }

    &.no-filters {
      padding-bottom: 0px !important;
      border-bottom: none;
    }

    .filter-name {
      color: #2E2E2E;
      font-size: 18px;
      font-weight: 700;
      line-height: 30px; 
    }
  }

  .filter-body {
    padding-top: $spacing-05;

    .filter-and-keyword {
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: 0.5px;
      color: #878787;
      padding: $spacing-04 0;

      &.edit-mode {
        padding: 0 0 $spacing-04 0;
      }
    }
  }
}