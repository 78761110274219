@import "app/assets/css/variables.scss";

.gooten-error-message {
  padding: $spacing-03 $spacing-05;
  border-radius: $spacing-03;

  svg {
    margin: -7px 10px 0 0;
  }
}
