.gooten-toggle-switch {
  position: relative;
  background-color: #B5B5B5;
  border-radius: 100px;
  transition: background-color 0.2s ease;
  cursor: pointer;

  .toggle-slider {
    position: absolute;
    background-color: #fff;
    border-radius: 100px;
    transition: left 0.2s ease;
  }

  &:hover {
    background-color: #878787;
  }

  &:active {
    background-color: #B5B5B5;
    box-shadow: 0 0 0 4px #CBDEFB;
  }

  &.disabled {
    cursor: default;
    background-color: #EDEDED !important;
    box-shadow: none !important;
  }

  &.size-small {
    width: 40px;
    height: 24px;

    .toggle-slider {
      width: 20px;
      height: 20px;
      top: 2px;
      left: 2px;
    }
  }

  &.size-medium {
    width: 50px;
    height: 32px;

    .toggle-slider {
      width: 24px;
      height: 24px;
      top: 4px;
      left: 4px;
    }
  }

  &.size-large {
    width: 64px;
    height: 40px;

    .toggle-slider {
      width: 32px;
      height: 32px;
      top: 4px;
      left: 4px;
    }
  }

  &.on {
    background-color: #171717;

    &.size-small {
      .toggle-slider {
        left: calc(100% - 22px);
      }
    }

    &.size-medium {
      .toggle-slider {
        left: calc(100% - 28px);
      }
    }

    &.size-large {
      .toggle-slider {
        left: calc(100% - 36px);
      }
    }

    &:hover {
      background-color: #2E2E2E;
    }

    &:active {
      background-color: #171717;
      box-shadow: 0 0 0 4px #CBDEFB;
    }

    &.disabled {
      cursor: default;
      background-color: #595959;
      box-shadow: none !important;
    }
  }
}
