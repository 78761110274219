@import "app/assets/css/variables.scss";

.status-chip {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 999px;
  font-weight: 400;
  width: fit-content;
  white-space: nowrap;
  border: 2px solid transparent;

  &.size-slim {
    padding: $spacing-01 $spacing-03;
    line-height: 24px;
    font-size: 14px;
  }

  &.size-small {
    padding: $spacing-01 $spacing-03;
    line-height: 24px;
    font-size: 16px;
  }

  &.size-medium {
    padding: $spacing-02 $spacing-03;
    line-height: 26px;
    font-size: 16px;
  }

  &.clickable {
    cursor: pointer;
  }

  .status-icon {
    margin-left: $spacing-02;
    margin-top: -4px;
  }
}
