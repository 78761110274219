@import "app/assets/css/variables.scss";

.additional-item-details {
  display: flex;
  flex-direction: column;
  gap: $spacing-04;

  .details-container {
    display: flex;
    gap: $spacing-06;
    align-items: flex-start; // Ensures the container aligns items from the start

    .item-image {
      width: 115px;
      height: 115px; 

      background: lightgray 50%;
      border-radius: $spacing-03;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        border-radius: $spacing-03;
      }
    }

    .item-details {
      flex: 1;
      display: grid;
      gap: $spacing-01;
      grid-template-columns: 1fr;

      &.many {
        grid-template-columns: 1fr 1fr;
      }

      .item-property {
        display: flex;
        gap: $spacing-04;

        .property-name {
          font-size: 14px;
          font-weight: 400;
          line-height: 24px;
          color:#878787;
        }

        .property-value {
          font-size: 14px;
          font-weight: 400;
          line-height: 24px;
          color: #2E2E2E;
        }
      }
    }
  }

  .image-details {
    .image-details-header {
      display: flex;
      align-items: center;
      gap: $spacing-04;
      justify-content: flex-start;
      width: 110px;
      font-size: 14px;
      font-weight: 700;
      line-height: 22px;
      cursor: pointer;
    }

    .image-details-table {

      td {
        vertical-align: top;
      }

      .our-url,
      .their-url {
        word-break: break-all;
      }

      .item-image-container {
        vertical-align: top;
        padding: 8px 0px !important;
        width: 70px;

        .item-image {
          padding: 0px !important;
          width: 60px;
          height: 60px; 
    
          background: lightgray 50%;
          border-radius: $spacing-03;
        }
  
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
          border-radius: $spacing-03;
        }
      }

      .image-actions {
        text-align: center;
      }
    }
  }
}
