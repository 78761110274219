.main-content {
  z-index: 2;
  background-color: #F2F2F2;
  border-top-left-radius: 0px;
  transition: margin-left 0.3s ease-in-out;
  width: 100%;
  height: 100%;
  min-height: 100vh;

 &.sidebar-visible {
    border-top-left-radius: 16px;
    margin-left: 229px;
  }
}