@import "app/assets/css/variables.scss";

.routing-details {
  display: flex;
  flex-direction: column;
  gap: $spacing-05;
  margin: $spacing-05;

  .no-strategies {
    text-align: center;
    height: 100px;
    padding-bottom: 0px !important;

    svg {
      margin: -5px 4px 0 0;
    }
  }

  .merchant-name-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    font-size: 24px;

    .merchant-name {
      display: flex;
      align-items: center;
      row-gap: 0px;
      column-gap: $spacing-04;
      font-size: 32px;
      font-weight: 600;
    }
  }

  .actions-column {
    text-align: center;
    vertical-align: top;
  }

  .status-column {
    display: flex;
    justify-content: center;
    margin-top: 5px;
  }
}