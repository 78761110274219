@import "app/assets/css/variables.scss";

.transformation-view {
  margin: $spacing-05;

  .transformation-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 32px;
    font-weight: 600;
    padding-bottom: $spacing-05;
  } 

  .search-bar-and-filters {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: $spacing-04;

    .search-bar {
      width: 100%;
      // max-width: 500px;
    }
  }

  .no-matching-results {
    text-align: center;
    width: 100%;
    padding: 40px 0;
  }

  .transformation-load-failed {
    width: 100%;
    text-align: center;
    padding: 50px 0;

    svg {  
      font-size: 18px;
      margin-right: 6px;
    }
  }
}