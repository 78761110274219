.selected-products {
  .product-container {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
  }

  .category-container {
    width: 100%;
    padding-top: 25px;

    .category-title {
      font-size: 20px;
      padding-bottom: 5px;
    }

    &:first-child {
      padding-top: 0px;
    }
  }

  .no-selected-products {
    color: red;
  }
}