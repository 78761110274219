@import "app/assets/css/variables.scss";

.vendors-view {
  padding: $spacing-05;

  .vendors-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 32px;
    font-weight: 600;
    padding-bottom: $spacing-05;
  }

  .search-bar-and-filters {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: $spacing-04;

    .search-bar {
      width: 100%;
      max-width: 500px;
    }

    .filter-field {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: $spacing-03;
      padding: $spacing-03;
      font-size: 14px;
      line-height: 24px;
      color: #2E2E2E;

      .filter-name {
        cursor: pointer;
        white-space: nowrap;
      }
    }
  }

  .table-body {
    .vendor-row {
      .vendor-status-chip {
        .status-chip {
          margin: 0 auto;
        }
      }
    }
  }

  .error-message {
    text-align: center;
    padding: 50px !important;

    svg {
      margin: -6px 3px 0 0;
    }
  }
}