@import "app/assets/css/variables.scss";

.item-details-view {
  .sort-by-collpase {
    display: flex;
    justify-content: space-between;
    padding-bottom: $spacing-05;

    .sort-by {
      font-size: 16px;
      font-weight: 400;
      color: #878787;
    }
  }

  .unassigned-items-header {
    display: flex;
    justify-content: space-between;
    color: #2E2E2E;
    font-size: 16px;
    font-weight: 700;
  }

  .item-cards {
    display: flex;
    flex-direction: column;
    gap: $spacing-05;
    padding-top: $spacing-03;
  }
}