@import "app/assets/css/variables.scss";

// override bootstraps primary colors
.btn-primary {
  font-family: 'OpenSans';
  color: #fff;
  background-color: #0a58ca;
  border: none;
  --bs-btn-padding-y : 0.3rem;
}
.btn-primary:hover {
  color: #fff;
  background-color: #1264df;
}
.btn-primary:focus, .btn-secondary.focus {
  box-shadow: none;
}
.btn-primary.disabled, .btn-secondary:disabled {
  color: #6c757d;
  background-color: #fff;
  border: none;
}
.btn-primary:not(:disabled):not(.disabled):active, 
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #373f49;
  background-color: #d8d8da;
}
.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: none;
  border: none;
}

// override bootstraps secondary colors
.btn-secondary {
  font-family: 'OpenSans';
  color: #5d646e;
  background-color: #e4e4e4;
  border: none;
  --bs-btn-padding-y : 0.3rem;
}
.btn-secondary:hover {
  color: #323a43;
  background-color: #d8d8da;
}
.btn-secondary:focus, .btn-secondary.focus {
  box-shadow: none;
}
.btn-secondary.disabled, .btn-secondary:disabled {
  color: #8c8c8c;
  background-color: #efefef;
  border: none;
}
.btn-secondary:not(:disabled):not(.disabled):active, 
.btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  color: #373f49;
  background-color: #d8d8da;
}
.btn-secondary:not(:disabled):not(.disabled):active:focus,
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: none;
  border: none;
}

// override bootstraps card header background color
.card .card-header {
  --bs-card-cap-bg: rgba(0, 0, 0, 0.05); 
}

// override bootstraps pagination colors
.page-item > .page-link {
  color: #373f49;
}

.page-item:hover > .page-link {
  color: #000000;
  background-color: #e9ecef;
  border-color: #e6e2de;
}

.page-item.disabled > .page-link {
  color: #a7abae;
  background-color: #fff;
  border-color: #dee2e6;
}

.page-item.active > .page-link {
  background-color: #6c757d;
  border-color: #6c757d;
  color: #fff;
}

.page-link:focus {
  box-shadow: none;
}

// allow tooltips to be wider
.tooltip {
  --bs-tooltip-max-width: 1000px;

  &.bs-tooltip-top {
    position: fixed;
  }
}

.form-control {
  color: #495057;

  // target if readonly and focused
  &:read-only {
    cursor: default;
    &:focus {
      box-shadow: none;
      border-color: #ced4da;
    }
  }
}