@import "app/assets/css/variables.scss";

.merchant-card {
  .merchant-information {
    display: flex;
    flex-direction: column;

    &.new-merchant {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: $spacing-05;

      @media screen and (max-width: 1200px) {
        grid-template-columns: 1fr; 
      }
    }

    .data-point {
      &:not(:last-child) {
        margin-bottom: $spacing-04;
      }
    }
  }

  .action-buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 12px;
    
    .btn-primary {
      margin-left: 8px;
    }
  }
}