@import "app/assets/css/variables.scss";

.shipment-card {
  .card-header {
    flex-direction: column;
  }

  .shipment-header {
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: $spacing-03;
    width: 100%;

    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;


    .id-status {
      display: flex;
      align-items: center;
      gap: $spacing-04;
      font-size: 20px;
      font-weight: 700;
      line-height: 32px;
      color: #2E2E2E;
    }
  }

  .shipment-data {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    column-gap: $spacing-07;
    row-gap: $spacing-03;
    color: #2E2E2E;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
  }

  .shipment-info-items {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .card-header {
    align-items: flex-start;
  }
}