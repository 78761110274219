@import "app/assets/css/variables.scss";

.package-card {
  display: flex;
  flex-direction: column;
  gap: $spacing-04;
  border-radius: 16px;
  background: #FCFCFC;

  &.multiple {
    border: 1px solid #E2E2E2;
    padding: $spacing-03 $spacing-04;
  }

  .package-card-header {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 65px;
    align-items: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    color: #2E2E2E;

    .package-num {
      margin-right: $spacing-04;
      font-size: 18px;
      font-weight: 700;
      line-height: 30px;
    }
  }

  .package-card-item {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    gap: $spacing-05;

    .package-data-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: flex-start;
      gap: $spacing-04;
      width: 100%;

      .id-status {
        flex: 1;
        min-width: 185px;
        display: flex;
        align-items: center;
        gap: $spacing-04;
        font-size: 20px;
        font-weight: 700;
        line-height: 32px;
        color: #2E2E2E;
        margin-right: 20px;

        .item-name {
          font-size: 16px;
          font-weight: 700;
          line-height: 26px;
        }
      }

      .external-id {
        flex: 1;
        min-width: 166px;
      }

      .sku {
        flex: 1.5;
        min-width: 200px;
      }

      .pricing-info {
        width: 235px;
        display: flex;
        flex-direction: row;
        gap: $spacing-04;

        .pricing-item {
          text-align: right;

          .pricing-title {
            color: #878787;
          }
        }
      }
    }

    .shipment-options {
      flex: 0 0 auto; 
      min-width: 35px; 
    }
  }
}