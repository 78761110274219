@import "app/assets/css/variables.scss";

.gooten-table-header {
  border-bottom: 2px solid #B5B5B5;

  .orderable {
    .column-header {
      cursor: pointer;
    }
  }
  
  :not(.orderable) {
    cursor: default;
  }

  svg {
    margin-left: 5px;
  }

  th {
    color: #2E2E2E;
    border: none;
    padding: $spacing-04;

    &:first-child {
      padding-left: $spacing-07 !important;
    }

    &:last-child {
      padding-right : $spacing-07 !important;
    }

    .column-header {
      display: flex;
      flex-direction: row;

      &.left {
        justify-content: left;
      }

      &.center {
        justify-content: center;
      }

      &.right {
        justify-content: right;
      }
    }
  }

  .order-status-header {
    text-align: center;
  }
}